import { Injectable } from "@angular/core";

import { FW } from "../framework/core";

@Injectable()
export class ContextService {
    private static _context: any = null;

    get current(): any {
        if (FW.isNull(ContextService._context)) {
            ContextService._context = new Object();
        }
        return ContextService._context;
    }
}
