import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ScopeService {

    private scopeKey: string;

    constructor() { }

    GetScopeKey(): string {

        if (window.location.hostname == "localhost") {
            this.scopeKey = environment.scopekeyDefault;
        }

        if (window.location.hostname == "clientes.passouganhou.com.br") {
            this.scopeKey = "ebwbank";
        }

        if (window.location.hostname == "associado.aessenai.org.br"
            || window.location.hostname == "associadohml.aessenai.org.br"
            || window.location.hostname == "canaldireto.sistema.site"
            || window.location.hostname == "canaldireto.web.app"
            || window.location.hostname == "canaldiretohml.web.app"
            || window.location.hostname == "canaldireto.firebaseapp.com"
            || window.location.hostname == "canaldiretohml.firebaseapp.com") {
            this.scopeKey = "aes";
        }

        return this.scopeKey;
    }

}
