import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FW } from '../framework/core';
import { Observable } from 'rxjs';
import { IService } from '../interfaces/Service';
import { ILegalEntity } from '../interfaces/ILegalEntity';

@Injectable({
    providedIn: 'root'
})
export class LegalEntityService {

    constructor(private apiService: ApiService) { }

    public IsIndebted<T>(legalEntityId): Observable<T> {
        return this.apiService.Call<T>("common", "svcLegalEntity", "IsIndebted", {
            "legalEntityId": legalEntityId
        });
    }

    public getCurrentLegalEntity<T>(): Observable<T> {
        return this.apiService.Call<T>("common", "svcLegalEntity", "GetCurrentLegalEntityWithDocuments", null);
    }

    public getCurrentLegalEntityNumber<T>(number): Observable<T> { 
        return this.apiService.Call<T>("common", "svclegalentity", "findindividual",  {"identificationNumber":number});
    }
    public getCurrentDependants<T>(): Observable<T> {
        return this.apiService.Call<T>("common", "svcLegalEntity", "getCurrentDependants", null);
    }

    public getdefaultindividualdocumenttype<T>(): Observable<T> {
        return this.apiService.Call<T>("common", "svcLegalEntity", "getdefaultindividualdocumenttype", null);
    }

    public getdefaultindividualidentificationtype<T>(): Observable<T> {
        return this.apiService.Call<T>("common", "svcLegalEntity", "getdefaultindividualidentificationtype", null);
    }


    public registerNewCustomerAccount<T>(legalEntity: ILegalEntity): Observable<T> {
        return this.apiService.Call<T>("common", "svcLegalEntity", "RegisterNewCustomerAccount", {
            "legalEntity": legalEntity
        });
    }
}
