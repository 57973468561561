import { Component } from "@angular/core";

@Component({
    selector: "app-page-not-found",
    templateUrl: "./pagenotfound.component.html",
})
export class PageNotFoundComponent {

    public href: string;
    public show:boolean = false;
    constructor() {

        let url: string; 
        
        if (sessionStorage.getItem("href") != '' && sessionStorage.getItem("href") != null) {
            url = '#/main/' + sessionStorage.getItem("href");
        } else {
            url = '#/login';
        } 

        this.href = url;
    }
}
