import { Injectable } from '@angular/core';
import { RecaptchaLoaderService } from '../services/RecaptchaLoaderService.service';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaV3Service {

  recaptchaSiteKey = "6LexqFUfAAAAAFn7GGq9zUaSOUNI2Id4GfEBxO5J";

  constructor(private recaptchaLoader: RecaptchaLoaderService) { }

  execute(action: string): Promise<string> {
    return this.recaptchaLoader.load().then(() => {
      return new Promise<string>((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(this.recaptchaSiteKey, { action })
            .then((token: string) => {
              resolve(token);
            })
            .catch((error: any) => {
              reject(error);
            });
        });
      });
    });
  }
}
