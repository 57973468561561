import { Component, OnInit } from '@angular/core';
import { SecurityService } from 'src/app/services/security.service';
import { ILogin } from 'src/app/interfaces/ILogin';
import { MessageService } from 'src/app/services/message.service';
import { Router } from '@angular/router';
import { ILegalEntity } from 'src/app/interfaces/ILegalEntity';
import { forkJoin } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
    public email;
    public name;
    public logo;
    constructor(
        private securityService: SecurityService,
        private messageService: MessageService,
        private router: Router) { }

    public ngOnInit() {
        this.name = localStorage.getItem("Name");
        this.logo = localStorage.getItem("LogoUrl");
    }

    public send() {
        this.securityService.VerifyEmailHasLegalEntity(this.email).pipe(
            concatMap(() => this.securityService.ToRequestPasswordChange(this.email))
        ).subscribe(
            (result) => {
                this.messageService.displayMessage({
                    message: 'E-mail enviado com Sucesso!',
                    warninglevel: 0
                });
                this.router.navigate(['login']);
            },
            error => this.onError(error)
        );
    }

    onError(error: any) {
        this.messageService.displayMessage(error);
    }
} 