import { Injectable } from "@angular/core";

import { FW } from "../framework/core";
import { ApiService } from "./api.service";
declare var getQueryString: any;
@Injectable()
export class AuthService {
    private static _userGUID: string = null;
    private static _userName: string = null;
    private static _userEmail: string = null;
    private static _userPhone: string = null;

    constructor(private apiService: ApiService) { }

    public tryToRestoreSession() {
        const content = {
            'token': localStorage.getItem('lastsessiontoken')
        };
        this.apiService.Call('framework', 'svcuser', 'restoresession', content)
            .subscribe(
                result => this.restoreSessionCallBack(result),
                error => this.onError(error)
            );
    }
    onError(error: any): void {
        alert(error.message);
    }

    restoreSessionCallBack(result) {
        if (!FW.isNull(result)) {
            localStorage.setItem("lastsessiontoken", result.sessiontoken);

            sessionStorage.setItem("sessiontoken", result.sessiontoken);
            sessionStorage.setItem("customizationkey", result.customizationkey);
            sessionStorage.setItem("currentlanguage", result.language);
            sessionStorage.setItem("username", result.username);
            sessionStorage.setItem("useremail", result.useremail);


            if (!FW.isNullOrBlank(result.photourl)) {
                sessionStorage.setItem("userphotourl", result.photourl);
            } else {
                sessionStorage.removeItem("userphotourl");
            }

        } else {
            setTimeout(() => {
                if (!FW.isNullOrBlank(getQueryString("goto"))) {
                    window.top.location.href = "./#/login?goto=" + encodeURIComponent(getQueryString("goto"));

                } else {
                    window.top.location.href = "./#/login?goto=" + encodeURIComponent(window.location.href);
                }
            }, 500);
        }
    }

    public get isSessionActive(): boolean {
        return !FW.isNullOrBlank(sessionStorage.getItem('sessiontoken'));
    }

    public get isSessionTempActive(): boolean {
        return !FW.isNullOrBlank(sessionStorage.getItem('temptoken'));
    }

    public get hasProfile(): boolean {
        return !FW.isNullOrBlank(AuthService._userName) && !FW.isNullOrBlank(AuthService._userEmail);
    }

    public setAuthenticated(userGUID: string): void {
        AuthService._userGUID = userGUID;
    }

    public setProfile(userName: string, userEmail: string, userPhone?: string): void {
        AuthService._userName = userName;
        AuthService._userEmail = userEmail;
        AuthService._userPhone = userPhone;
    }

    public restoreSession(): void {
        const userGUID: string = FW.storage.getString("userguid");
        const userName: string = FW.storage.getString("username");
        const userEmail: string = FW.storage.getString("useremail");
        const userPhone: string = FW.storage.getString("userphone");

        this.setAuthenticated(userGUID);
        this.setProfile(userName, userEmail, userPhone);
    }

    public saveSession(userGUID: string, userName: string, userEmail: string, userPhone: string): void {
        FW.storage.setString("userguid", userGUID);
        FW.storage.setString("username", userName);
        FW.storage.setString("useremail", userEmail);
        FW.storage.setString("userphone", userPhone);

        this.setAuthenticated(userGUID);
        this.setProfile(userName, userEmail, userPhone);
    }
}
