import { Directive, ElementRef, Input } from '@angular/core';

import { IFieldMask } from 'src/app/interfaces/interfaces';
import { FW } from '../../framework/core';
declare var $: any;

@Directive({
    selector: '[fieldMask]'
})
export class FieldMaskDirective {
    @Input('fieldMask') mask: string;

    constructor(element: ElementRef) {
        setTimeout(this.applyMask.bind(this, element), 100);
    }

    private applyMask(element: ElementRef): void {
        let parsedMask: IFieldMask = null;

        if (FW.isNullOrBlank(this.mask)) {
            return;
        }

        try {
            parsedMask = JSON.parse(this.mask) as IFieldMask;
        } catch (e) {
            switch (this.mask) {
                case "decimal":
                    parsedMask = {
                        format: "000.000.000.000,00",
                        clearIfNotMatch: false,
                        reverse: true,
                        selectOnFocus: true
                    } as IFieldMask;
                    break;
                case "decimalPrecision":
                    parsedMask = {
                        format: "000.000.000.000,000",
                        clearIfNotMatch: false,
                        reverse: true,
                        selectOnFocus: true
                    } as IFieldMask;
                    break;
                case "money":
                    parsedMask = {
                        format: "#.##0,00",
                        clearIfNotMatch: true,
                        reverse: true,
                        selectOnFocus: true
                    } as IFieldMask;
                    break;
                default:
                    parsedMask = {
                        format: this.mask,
                        clearIfNotMatch: true,
                        reverse: false,
                        selectOnFocus: true
                    } as IFieldMask;
                    break;
            }
        }

        $(element.nativeElement).mask(parsedMask.format, {
            clearIfNotMatch: parsedMask.clearIfNotMatch,
            reverse: parsedMask.reverse,
            placeholder: parsedMask.placeholder,
            selectOnFocus: parsedMask.selectOnFocus
        });
    }
}