import { Component, OnInit } from '@angular/core';
import { SecurityService } from 'src/app/services/security.service';
import { MessageService } from 'src/app/services/message.service';
import { Router } from '@angular/router';

declare var viewPassword: any;
@Component({
    selector: 'app-request-access',
    templateUrl: './request-access.component.html'
})
export class RequestAccessComponent implements OnInit {

    public name;
    public email;
    public phone;
    public company;
    public logo;

    constructor(private router: Router,
        private securityService: SecurityService,
        private messageService: MessageService) { }

    public ngOnInit() {
        this.loadingSession();
    }


    public onError(error: any) {
        this.messageService.displayMessage(error);
    }

    public send() {
        console.log(this.name);
        console.log(this.email);
        console.log(this.phone);
        console.log(this.company);
    }

    public loadingSession() {
        this.logo = localStorage.getItem("LogoUrl");
    }

    public goBack() {
        this.router.navigate([`\login`]);
    }
}
