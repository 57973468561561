import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FW } from '../framework/core';
import { Observable } from 'rxjs';
import { IService } from '../interfaces/Service';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {
    
    constructor(private apiService: ApiService) { }
    
    listServices<T> (): Observable<T> {
        return this.apiService.Call<T>("common", "svcService", "search", null);
    }
}
