import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DynamicPropertyService {

    constructor(private apiService: ApiService) { }

    public ListDynamicPropertyOptions<T>(propertyGUID): Observable<T> {
        return this.apiService.Call<T>("cms", "svcDynamicProperty", "ListDynamicPropertyOptions", { "propertyGUID": propertyGUID });
    }

    public ListEntitiesAsDynamicOptions<T>(entityReference): Observable<T> {
        return this.apiService.Call<T>("cms", "svcDynamicProperty", "ListEntitiesAsDynamicOptions", { "entityReference": entityReference });
    }

}
