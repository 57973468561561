import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ISearchSettings } from '../interfaces/ISearchSettings';
import { Observable } from 'rxjs';
import { IIdentificationTypeEntity } from '../interfaces/IIdentificationTypeEntity';
import { FW } from '../framework/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdentificationTypeService {

  constructor(private apiService: ApiService) { }

  public GetByLegalEntityType<IdentificationTypeService>(legalEntityType: number): Observable<IIdentificationTypeEntity> {
    const searchSettings: ISearchSettings = {
      filters: [
        {
          field: "LegalEntityType",
          value: legalEntityType
        }
      ]
    };


    return this.Search<IIdentificationTypeEntity>(searchSettings).pipe(
      map((result) => {
        let ident;
        if (!FW.isNullOrEmpty(result))
          ident = result[0];

        return ident;
      })
    );
  }

  private Search<T>(searchSettings: ISearchSettings): Observable<T> {
    const settings = {
      "settings": searchSettings
    }
    return this.apiService.Call<T>("common", "svcIdentificationType", "ExternalSearch", settings);
  }
}
