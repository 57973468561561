import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProviders } from './http-interceptor/index';
import { AppServices } from 'src/app/services/index';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/security/login.component';
import { PageNotFoundComponent } from './pages/404/pagenotfound.component';
import { SignupComponent } from './components/signup/signup.component';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmRegistrationComponent } from './components/confirm-registration/confirm-registration.component';
import { ForgotPasswordComponent } from './pages/security/forgot-password.component';
import { ChangePasswordOutComponent } from './pages/security/change-out-password.component';
import { RequestAccessComponent } from './pages/security/request-access.component';
import { DataUpdateComponent } from './pages/security/data-update.component';
import { InfoRequestComponent } from './pages/inforequest/inforequest.component';
import { InfoRequestTemplateComponent } from './pages/inforequesttemplate/inforequesttemplate.component';
import { FieldMaskDirective } from './controls/directives/fieldmask.directive';
import { SharedModule } from './pages/main/shared.module';


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ToastrModule.forRoot(),
        UiSwitchModule,
        NgxMaskModule.forRoot(),
        SharedModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        InfoRequestComponent,
        PageNotFoundComponent,
        SignupComponent,
        ConfirmRegistrationComponent,
        DataUpdateComponent,
        ForgotPasswordComponent,
        ChangePasswordOutComponent,
        RequestAccessComponent,
        InfoRequestTemplateComponent,
        FieldMaskDirective
    ],
    providers: [AppServices, httpInterceptorProviders,
      // {
      //   provide: RECAPTCHA_V3_SITE_KEY,
      //   useValue: '6LexqFUfAAAAAFn7GGq9zUaSOUNI2Id4GfEBxO5J'
      // }
    ],
    bootstrap: [AppComponent],
    exports: [FieldMaskDirective]

})
export class AppModule { }
