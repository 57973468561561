import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMessage } from '../interfaces/IMessage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private toastr: ToastrService
  ) { }

  displayMessage(msg: IMessage): void {
    switch (msg.warninglevel) {
      case 0:
        this.toastr.success(msg.message);
        break;
      case 1:
        this.toastr.warning(msg.message);
        break;
      case 2:
        this.toastr.error(msg.message);
        break;
      default:
        this.toastr.error(msg.message);
        break;
    }
  }


}
