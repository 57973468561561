import { Session } from "./session";
import { Storage } from "./storage";
import { Queryable } from './queryable';

declare function moment(value: string | Date): any;

export class FW {
  private static _storage: Storage = null;
  private static _session: Session = null;

  public static isNull(value: any): boolean {
    return ((value === null) || (value === undefined));
  }



  public static isNullOrEmpty(value: any): boolean {
    if (FW.isNull(value)) { return true; }

    if (typeof value === "string") {
      return ((value as string).length === 0);
    } else if (value instanceof Array) {
      return ((<Array<any>>value).length === 0);
    }

    return false;
  }


  public static isNullOrBlank(value: string): boolean {
    if (FW.isNullOrEmpty(value)) { return true; }
    if (typeof value === "string") {
      return (value.trim().length === 0);
    } else {
      return false;
    }
  }

  public static generateNewGUID(): string {
    const s4 = function () {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  public static randomize(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // STORAGE

  public static get storage(): Storage {
    if (FW.isNull(FW._storage)) { FW._storage = new Storage(); }
    return FW._storage;
  }

  // SESSION

  public static get session(): Session {
    if (FW.isNull(FW._session)) { FW._session = new Session(); }
    return FW._session;
  }

  public static replaceAll(text, oldValue, newValue): string {
    if (FW.isNullOrBlank(text)) { return text; }
    while (text.indexOf(oldValue) >= 0) {
      text = text.replace(oldValue, newValue);
    }
    return text;
  }

  public static query<T>(collection: Array<T>): Queryable<T> {
    return new Queryable<T>(collection);
  }

  public static decimalToString(input, size) {
    if (FW.isNullOrBlank(input) || (typeof input === "number" && isNaN(input))) {
      return "";
    }
    if (isNaN(input) || (typeof input === "string")) {
      input = FW.parseToDecimal(input, size);
    }

    return input.toLocaleString("pt-BR", { style: "decimal", useGrouping: true, minimumFractionDigits: size, maximumFractionDigits: size });

    // switch (window.cultureInfo) {
    //     case "en-US":
    //         return input.toLocaleString("en-US", { style: "decimal", useGrouping: true, minimumFractionDigits: fractionSize, maximumFractionDigits: fractionSize });
    //     case "pt-BR":
    //         return input.toLocaleString("pt-BR", { style: "decimal", useGrouping: true, minimumFractionDigits: fractionSize, maximumFractionDigits: fractionSize });
    // }
  }

  public static parseToDecimal(inputValue, fractionSize) {
    if (FW.isNullOrBlank(inputValue)) {
      return null;
    }

    if (typeof inputValue === "number" && !isNaN(inputValue)) {
      return inputValue;
    }

    return eval(parseFloat(FW.replaceAll(inputValue, ".", "").replace(",", ".")).toFixed(fractionSize));

    // switch (culture) {
    //     default:
    //     case "en-US":
    //         return eval(parseFloat(replaceAll(inputValue, ",", "")).toFixed(fractionSize));
    //     case "pt-BR":
    //         return eval(parseFloat(replaceAll(inputValue, ".", "").replace(",", ".")).toFixed(fractionSize));
    // }
  }
}

export function formatDate(value: string | Date, format: string) {
  return moment(value).format(format);
}







