import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { isNull } from 'util';

@Injectable({
    providedIn: 'root'
})
export class InfoRequestService {

    constructor(private apiService: ApiService) { }

    public GetInfoRequest<T>(guid): Observable<T> {
        return this.apiService.Call<T>("crm", "svcinforequest", "GetInfoRequest", { 'uuid': guid });
    }

    public GetRemoteFile<T>(value): string {
        var resourceUrl = (false || "") + "/resource/framework/svcfile/GetRemoteFile/?reference=" + value;
        var customizationkey = localStorage.getItem("customizationkey");
        var querystring = "";

        querystring += "&sessiontoken=" + encodeURIComponent(sessionStorage.getItem('temptoken'));

        if (!isNull(customizationkey)) {
            if (querystring.length > 0) { querystring += "&"; }
            querystring += "customizationkey=" + localStorage.getItem("customizationkey");
        }

        resourceUrl += querystring;

        return resourceUrl;
    }

    public GetByGUIDInfoRequest<T>(guid): Observable<T> {
        return this.apiService.Call<T>("crm", "svcinforequest", "GetByGUIDExcludedNull", { 'uuid': guid });
    }

    public GetInfoRequestTemplateGUID<T>(guid): Observable<T> {
        return this.apiService.Call<T>("crm", "svcinforequesttemplate", "GetByGUID", { 'guid': guid });
    }

    public SendTempPassword<T>(guid, email): Observable<T> {
        return this.apiService.Call<T>("crm", "svcinforequest", "SendTempPassword", { 'uuid': guid, 'email': email });
    }

    public GetInfoRequestTemplate<T>(guid, secretCode): Observable<T> {
        return this.apiService.Call<T>("crm", "svcinforequest", "GetInfoRequestTemplate", { 'uuid': guid, 'secretCode': secretCode });
    }


    public UploadFile<T>(guid, fieldKey, extension, fileContent): Observable<T> {
        return this.apiService.Call<T>("crm", "svcinforequest", "UploadFile", { 'uuid': guid, 'fieldKey': fieldKey, 'extension': extension, 'fileContent': fileContent });
    }

    public SaveResponse<T>(uuid, response): Observable<T> {
        return this.apiService.Call<T>("crm", "svcinforequest", "SaveResponse", { 'uuid': uuid, 'response': response });
    }

}
