import { Injectable } from '@angular/core';
import { Router, CanActivate } from "@angular/router";

import { AuthService } from "./auth.service";

@Injectable()
export class RouteGuardTempService implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate() {

        if (!this.authService.isSessionTempActive) {
            console.log("Authentication required!");
            this.router.navigate(["/login"]);
            return false;
        }

        return true;
    }
}
