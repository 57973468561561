import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SecurityService } from 'src/app/services/security.service';
import { FW } from '../../framework/core';
import { ILogin } from 'src/app/interfaces/ILogin';
import { MessageService } from 'src/app/services/message.service';
import { IInterfaceConfiguration } from 'src/app/interfaces/IInterfaceConfiguration';
import { ILegalEntity } from 'src/app/interfaces/ILegalEntity';
import { RecaptchaV3Service } from '../../services/RecaptchaV3Service.service';

declare var viewPassword: any;
declare var getQueryString: any;
declare var encodeURIComponent: any;
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    public user;
    public password = '';
    public allowsSignUp = false;
    public name;
    public logo;
    public continueUrl = getQueryString("goto");

    constructor(
        private securityService: SecurityService,
        private messageService: MessageService,
        private recaptchaV3Service : RecaptchaV3Service,
        private router: Router) { }

    public ngOnInit() {
        this.securityService.ToInterfaceConfiguration<IInterfaceConfiguration>()
            .subscribe(
                (result: IInterfaceConfiguration) => {
                    this.securityService.InterfaceConfigurationSuccess(result);
                    this.loadingSession();
                },
                error => this.onError(error)
            );
    }

    public loadingSession() {
        this.allowsSignUp = JSON.parse(localStorage.getItem("AllowsSignUp"));
        this.name = localStorage.getItem("Name");
        this.logo = localStorage.getItem("LogoUrl");
    }

    public displayPassword() {
        viewPassword('password');
    }

    public executeLoginAction(): void{
      this.recaptchaV3Service.execute('login').then((token)=> this.login(token));
    }

    public login(token:string) {
        this.securityService.doLogin<ILogin>(this.user, this.password, token)
            .subscribe(
                (result: ILogin) => {
                    this.securityService.loginSuccess(result);
                    if (!FW.isNull(result)) {
                        this.securityService.getCurrentLegalEntity<ILegalEntity>()
                            .subscribe(
                                (legalEntity: ILegalEntity) => {
                                    sessionStorage.setItem("identificationNumber", legalEntity.IdentificationNumber);
                                    sessionStorage.setItem("legalEntityId", legalEntity.Id);
                                    if (legalEntity.ExtendedProperties.OutdatedContactInfo) {
                                        this.router.navigate(['/data/update/']);
                                    } else {
                                        if (!FW.isNullOrBlank(this.continueUrl)) {
                                            window.location.href = this.continueUrl;
                                        } else {
                                            this.router.navigate(['/main/home']);
                                        }
                                    }
                                },
                                error => this.onError(error)
                            );
                    }
                },
                error => this.onError(error)
            );
    }

    public onError(error: any) {
        this.messageService.displayMessage(error);
    }
}
