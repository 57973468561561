import { Component, OnInit } from '@angular/core';
import { SecurityService } from 'src/app/services/security.service';
import { ILogin } from 'src/app/interfaces/ILogin';
import { MessageService } from 'src/app/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var viewPassword: any;
@Component({
    selector: 'app-change-out-password',
    templateUrl: './change-out-password.component.html'
})
export class ChangePasswordOutComponent implements OnInit {
    public name;
    public logo;
    public token;
    public newPassword;
    public confirmPassword;
    constructor(
        private securityService: SecurityService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private router: Router) { }

    public ngOnInit() {
        this.name = localStorage.getItem("Name");
        this.logo = localStorage.getItem("LogoUrl");
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    public displayNewPassword() {
        viewPassword('newPassword');
    }

    public displayConfirmPassword() {
        viewPassword('confirmPassword');
    }

    public ChangePassword() {
        this.securityService.changeforgottenpassword(this.token, this.newPassword, this.confirmPassword)
            .subscribe(
                (result) => {
                    this.messageService.displayMessage({
                        message: 'Senha Alterada com sucesso!',
                        warninglevel: 0
                    });
                    this.router.navigate(['login']);
                },
                error => this.onError(error)
            );
    }

    onError(error: any) {
        this.messageService.displayMessage(error);
    }
} 