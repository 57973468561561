import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SecurityService } from 'src/app/services/security.service';
import { FW } from '../../framework/core';
import { ILogin } from 'src/app/interfaces/ILogin';
import { MessageService } from 'src/app/services/message.service';
import { IInterfaceConfiguration } from 'src/app/interfaces/IInterfaceConfiguration';
import { ILegalEntity } from 'src/app/interfaces/ILegalEntity';
import { InfoRequestService } from 'src/app/services/inforequest.service';
import { DynamicPropertyService } from 'src/app/services/dynamic-property.service';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

declare var viewPassword: any;
declare var $: any;
declare var MultipleDatepicker: any;
@Component({
    selector: 'app-inforequesttemplate',
    templateUrl: './inforequesttemplate.component.html'
})
export class InfoRequestTemplateComponent implements OnInit {
    public allowsSignUp = false;
    public name;
    public logo;
    public infotitle = "";
    public requestGuid = "";

    public templateGuid;
    public template;

    public model;
    public collection = [];
    public fielkeyaux;
    public extension;
    public indexaux;
    public show = false;
    public success = false;

    public response;
    constructor(
        private securityService: SecurityService,
        private messageService: MessageService,
        private dynamicService: DynamicPropertyService,
        private infoRequestService: InfoRequestService,
        private route: ActivatedRoute,
        private router: Router,
        private cdRef: ChangeDetectorRef) { }

    public ngOnInit() {

        this.loader(true);
        this.name = localStorage.getItem("Name");
        this.route.queryParams
            .subscribe(params => {
                this.templateGuid = params.template;
                this.requestGuid = params.request;
                this.GetInfoRequest();

            },
                error => this.onError(error)
            );
    }


    public GetInfoRequest() {
        this.infoRequestService.GetByGUIDInfoRequest(this.requestGuid).subscribe((request: any) => {

            this.infoRequestService.GetInfoRequestTemplateGUID(this.templateGuid).subscribe((template: any) => {
                this.template = template;
                this.infotitle = template.Name;
                this.model = JSON.parse(template.Model);
                this.collection = this.model.collection;
                this.ListAllPropertyOptions();
                if (request.Response != null) {
                    this.response = JSON.parse(request.Response);
                    this.getResponse();
                }
            }),
                error => this.onError(error);
        },
            error => this.onError(error));
    }

    public getUrlFile(value) {
        if (value == null) return null;
        return localStorage.getItem("BaseUrl") + this.infoRequestService.GetRemoteFile(encodeURIComponent((btoa(value))));
    }

    public getResponse() {
        for (var i = 0; i < this.collection.length; i++) {
            if (this.response.hasOwnProperty(this.collection[i].fieldKey)) {
                if (this.collection[i].controlType == 'decimal') {
                    this.collection[i].value = FW.decimalToString(this.response[this.collection[i].fieldKey], 2);
                } else {
                    this.collection[i].value = this.response[this.collection[i].fieldKey];

                }

            }
        }
        this.loader(false);
    }

    public ListAllPropertyOptions() {
        var listchamadas = [];
        let result = null;
        for (var i = 0; i < this.collection.length; i++) {
            if (this.collection[i].controlType == 'dynamicoptions') {
                listchamadas.push(this.dynamicService.ListDynamicPropertyOptions(this.collection[i].propertyUUID))
            }
            if (this.collection[i].controlType == 'referenceoptions') {
                listchamadas.push(this.dynamicService.ListEntitiesAsDynamicOptions(this.collection[i].entityReference))

            }
            if (this.collection[i].controlType == 'checkbox' && !this.collection[i].hasOwnProperty("value")) {
                this.collection[i].value = false;
            }
        }
        if (FW.isNullOrEmpty(listchamadas)) {
            this.loader(false);
        } else {
            this.ForCall(listchamadas).subscribe((forResult: any) => {
                if (!FW.isNullOrEmpty(forResult)) {
                    for (var i = 0; i < forResult.result.length; i++) {
                        result = forResult.result[i];
                        for (var j = 0; j < this.collection.length; j++) {
                            if (this.collection[j].controlType == 'dynamicoptions') {
                                if (this.collection[j].propertyUUID == result[0].ExtendedProperties.propertyGUID) {
                                    this.collection[j].optionList = result;
                                }
                            }
                            if (this.collection[j].controlType == 'referenceoptions') {
                                if (this.collection[j].entityReference == result[0].ExtendedProperties.OptionEntityReference) {
                                    this.collection[j].optionList = result;
                                }
                            }
                        }
                    }
                }
                this.loader(false);
            });
        }
    }

    public ForCall(listchamadas) {
        return forkJoin(listchamadas).pipe(
            map((result) => {
                return {
                    "result": result
                }
            })
        )
    }


    public SaveResponse() {
        if (this.show) return;
        this.loader(true);
        let response = {};
        for (var i = 0; i < this.collection.length; i++) {
            let item = this.collection[i];
            if (item.hasOwnProperty("value")) {
                if (item.required && (item.value == null || item.value.toString().trim() == "")) {
                    this.messageService.displayMessage({
                        message: "O campo " + item.label + " é obrigatório",
                        warninglevel: 1
                    });
                    this.loader(false);
                    return;
                }
                response[item.fieldKey] = item.value;
                if (this.collection[i].controlType == 'text') {
                    response[item.fieldKey] = $("#textvalue" + i).val();
                }
                if (this.collection[i].controlType == 'decimal') {
                    response[item.fieldKey] = FW.parseToDecimal($('#decimal' + i).val(), 2);
                }
            } else {
                if (item.required) {
                    this.messageService.displayMessage({
                        message: "O campo " + item.label + " é obrigatório",
                        warninglevel: 1
                    });
                    this.loader(false);
                    return;
                } else {
                    response[item.fieldKey] = "";
                }
            }
        }
        this.infoRequestService.SaveResponse(this.requestGuid, JSON.stringify(response)).subscribe((result) => {
            this.loader(false);
            this.success = true;
        },
            error => this.onError(error));
    }

    public loadingSession() {
        this.allowsSignUp = JSON.parse(localStorage.getItem("AllowsSignUp"));
        this.name = localStorage.getItem("Name");
        this.logo = localStorage.getItem("LogoUrl");
    }

    public saveBlob(e, item, index) {
        this.loader(true);
        var file = e.target.files[0];
        var reader = new FileReader();
        this.fielkeyaux = item.fieldKey;
        this.extension = file.name.split('.').pop();
        if (item.controlFileType.length == 0 || (item.controlFileType.length > 0) && item.controlFileType.indexOf("." + this.extension) > -1) {
            this.indexaux = index;
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(file);
        } else {
            this.messageService.displayMessage({
                message: "Arquivo inválido para o campo " + item.label + ", as extensão válidas são " + item.controlFileType.toString(),
                warninglevel: 1
            });
            this.loader(false);
        }
    }

    public removeFile(index) {
        this.collection[index].value = null;

    }

    public loader(action: boolean) {
        if (action !== this.show) {
            this.show = action;
            this.cdRef.detectChanges();
        }
    }

    _handleReaderLoaded(e) {
        var reader = e.target;
        console.log(reader);
        this.infoRequestService.UploadFile(this.requestGuid, this.fielkeyaux, this.extension, reader.result.split(',')[1]
        ).subscribe((result) => {
            this.loader(false);
            this.collection[this.indexaux].value = result;
        },
            error => this.onError(error));
    }



    public onError(error: any) {
        this.loader(false);
        this.messageService.displayMessage(error);
    }
}
