import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { ContextService } from './context.service';
import { LoaderService } from './loader.service';
import { MessageService } from './message.service';
import { RouteGuardService } from './routeguard.service';
import { SecurityService } from './security.service';
import { ServicesService } from './services.service';
import { LegalEntityTypeService } from './legalentitytype.service';
import { IdentificationTypeService } from './identification-type.service';
import { UserService } from './user.service';
import { RouteGuardTempService } from './routeguardtemp.service';
import { ScopeService } from './scope.service';

export const AppServices = [
    ApiService,
    AuthService,
    ContextService,
    ScopeService,
    LoaderService,
    MessageService,
    RouteGuardService,
    RouteGuardTempService,
    SecurityService,
    ServicesService,
    LegalEntityTypeService,
    IdentificationTypeService,
    UserService
];