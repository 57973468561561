import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';

import { AuthService } from "./services/auth.service";
import { RouteGuardService } from "./services/routeguard.service";

import { LoginComponent } from "./pages/security/login.component";
import { PageNotFoundComponent } from "./pages/404/pagenotfound.component";
import { SignupComponent } from "./components/signup/signup.component";
import { ConfirmRegistrationComponent } from "./components/confirm-registration/confirm-registration.component";
import { ForgotPasswordComponent } from './pages/security/forgot-password.component';
import { ChangePasswordOutComponent } from './pages/security/change-out-password.component';
import { RequestAccessComponent } from "./pages/security/request-access.component";
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { DataUpdateComponent } from "./pages/security/data-update.component";
import { InfoRequestComponent } from "./pages/inforequest/inforequest.component";
import { InfoRequestTemplateComponent } from "./pages/inforequesttemplate/inforequesttemplate.component";
import { RouteGuardTempService } from "./services/routeguardtemp.service";

const routes: Routes = [
    { path: "", redirectTo: "main", pathMatch: "full" },
    { path: "main", loadChildren: "./pages/main/main.module#MainModule", canActivate: [RouteGuardService] },
    { path: "login", component: LoginComponent },
    { path: "inforequest/:guid", component: InfoRequestComponent },
    { path: "template", component: InfoRequestTemplateComponent, canActivate: [RouteGuardTempService] },
    { path: "signup", component: SignupComponent },
    { path: "registration", component: ConfirmRegistrationComponent },
    { path: "forgotpassword", component: ForgotPasswordComponent },
    { path: "changepassword", component: ChangePasswordOutComponent },
    { path: "request/access", component: RequestAccessComponent },
    { path: "data/update", component: DataUpdateComponent },
    { path: '**', component: PageNotFoundComponent }

];

export const authProviders = [AuthService];

registerLocaleData(localePt)


@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [AuthService, RouteGuardService, {
        provide: LOCALE_ID,
        useValue: "pt-BR"
    }]
})
export class AppRoutingModule { }
