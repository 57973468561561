import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/security.service';
import { ILegalEntity } from 'src/app/interfaces/ILegalEntity';

@Component({
    selector: 'app-data-update',
    templateUrl: './data-update.component.html'
})
export class DataUpdateComponent implements OnInit {
    public show = false;
    public email: string;
    public ddd: string;
    public mobile: string;
    public legalEntityId: string;

    constructor(private securityService: SecurityService,
        private router: Router,
        private messageService: MessageService,
        private cdRef: ChangeDetectorRef) { }

    public ngOnInit() {
        this.legalEntityId = sessionStorage.getItem("legalEntityId");
        console.log(this.legalEntityId);
        this.clear();
    }


    onError(error: any) {
        this.loader(false);
        this.messageService.displayMessage(error);
    }

    // this.router.navigate([`/main/booking/create`]);

    public change() {
        this.loader(true);
        const content = {
            "email": this.email,
            "areaCode": this.ddd,
            "mobile": this.mobile,
        };
        this.securityService.UpdateLegalEntityContactInfo<ILegalEntity>(content)
            .subscribe(
                (result: ILegalEntity) => {
                    this.loader(false);
                    if (result) {
                        this.router.navigate(['/main/home']);
                    }
                },
                error => this.onError(error)
            );
    }

    public loader(action: boolean) {
        if (action !== this.show) {
            this.show = action;
            this.cdRef.detectChanges();
        }
    }

    public clear(): void {
        this.show = false;
        this.email = "";
        this.ddd = "";
        this.mobile = "";
    }
}
