import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ISearchSettings } from '../interfaces/ISearchSettings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LegalEntityTypeService {

  constructor(private apiService: ApiService) { }

  public Search<T>(searchSettings: ISearchSettings): Observable<T> {
    return this.apiService.Call<T>("common", "svclegalentitytype", "ExternalSearch", searchSettings);
  }
}
