import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { FW } from "../framework/core";
import { MessageService } from './message.service';
import { IMessage } from '../interfaces/IMessage';
import { ScopeService } from './scope.service';
import { environment } from 'src/environments/environment';
declare var getQueryString: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public hml: boolean = true;

  private baseAppPath = environment.baseApiUrl;

  constructor(private http: HttpClient, private messageService: MessageService,
    private scopeService: ScopeService) { }

  Call<T>(module: string, serviceName: string, methodName: string, content: any): Observable<T> {
    let sessiontoken = sessionStorage.getItem('sessiontoken');
    if (sessiontoken == null || sessiontoken == "")
      sessiontoken = sessionStorage.getItem('temptoken');
    const customizationkey = sessionStorage.getItem('customizationkey');
    let envelope: any = {};
    var options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessiontoken,
        'x-scope-key': this.scopeService.GetScopeKey()
      })
    };

    if (!FW.isNullOrEmpty(customizationkey)) {
      options.headers.append('customizationkey', customizationkey);
    }
    if (!FW.isNullOrEmpty(content)) {
      envelope.content = content;
    }

    return this.http.post<T>(`${this.baseAppPath}/api/legacy/${module}/${serviceName}/${methodName}`, JSON.stringify(envelope), options)
      .pipe(
        tap((result: any) => {
          if (!FW.isNullOrEmpty(result.sessiontoken)) {
            // sessionStorage.setItem("sessiontoken", result.sessiontoken); n deixa a sessão cair, buga o site n descomentar
          }
        }),
        map(res => res.content as T),
        catchError(this.errorHandler)
      );
  }

  CallPost<T>(module: string, serviceName: string, methodName: string): Observable<T> {
    const sessiontoken = sessionStorage.getItem('sessiontoken');

    var options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessiontoken,
        'x-scope-key': this.scopeService.GetScopeKey()
      })
    };

    return this.http.post<T>(`${this.baseAppPath}/api/legacy/${module}/${serviceName}/${methodName}`, {}, options)
      .pipe(
        tap((result: any) => {
          if (!FW.isNullOrEmpty(result.sessiontoken)) {
            sessionStorage.setItem("sessiontoken", result.sessiontoken);
          }
        }),
        map(res => res.content as T),
        catchError(this.errorHandler)
      );
  }

  CallGet<T>(module: string, serviceName: string, methodName: string, content: any): Observable<T> {

    var options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem("sessiontoken"),
        'x-scope-key': this.scopeService.GetScopeKey()
      })
    };

    return this.http.get<T>(`${this.baseAppPath}/api/resource/${module}/${serviceName}/${methodName}?` + content, options)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  CallGetExterno<T>(serviceName: string, content: any): Observable<T> {

    var options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        //'Authorization': 'Bearer ' + sessionStorage.getItem("sessiontoken")
      })
    };

    return this.http.get<T>(`https://api.aessenai.org.br/api/${serviceName}?` + content, options)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  errorHandler(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 560 || errorResponse.status === 401 || errorResponse.status === 403) {
      try {
        if (typeof errorResponse.error === 'object') {
          return throwError(errorResponse.error);
        } else {
          const apiError = JSON.parse(errorResponse.error);
          return throwError(apiError as IMessage);
        }
      } catch (e) {
        if (errorResponse.status === 560) {
          this.messageService.displayMessage(errorResponse.error as IMessage);
        }
      }
      if (errorResponse.status === 401 || errorResponse.status === 403) {
        if (!FW.isNullOrBlank(getQueryString("goto"))) {
          window.top.location.href = "./#/login?goto=" + encodeURIComponent(getQueryString("goto"));

        } else {
          window.top.location.href = "./#/login?goto=" + encodeURIComponent(window.location.href);
        }
      }
    }

    if (errorResponse.status === 520) {
      console.log(errorResponse.error);

      const defaultError: IMessage = {
        message: 'Erro desconhecido',
        warninglevel: 2
      }
      return throwError(defaultError);
    }

    console.log(errorResponse);
    const defaultError: IMessage = {
      message: 'Algo errado aconteceu. Por favor, tente novamente mais tarde.',
      warninglevel: 2
    }
    return throwError(defaultError);
  }
}
