import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FW } from '../framework/core';
import { Observable } from 'rxjs';
import { MD5 } from "crypto-js";
import { ILogin } from '../interfaces/ILogin';
import { Router } from '@angular/router';
import { IChangePassword } from '../interfaces/IChangePassword';
import { IInterfaceConfiguration } from '../interfaces/IInterfaceConfiguration';
import { LayoutService } from './layout.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(
    private apiService: ApiService,
    private router: Router) { }

  doLogin<T>(login: string, password: string, token:string): Observable<T> {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

    if (!FW.isNullOrEmpty(password)) {
      password = MD5(password).toString();
    }

    if (reg.test(login)) {
      const loginContent = {
        'email': login,
        'passwordHash': password,
        'token':token
      };
      return this.apiService.Call<T>('framework', 'svcuser', 'validatelogin', loginContent);
    } else {
      const loginContent = {
        'identificationNumber': login,
        'passwordHash': password,
        'token':token
      };
      return this.apiService.Call<T>('common', 'svcLegalEntity', 'ValidateLoginByIdentification', loginContent);
    }
  }

  getCurrentLegalEntity<T>(): Observable<T> {
    return this.apiService.Call<T>('common', 'svcLegalEntity', 'GetCurrentLegalEntity', {});
  }

  UpdateLegalEntityContactInfo<T>(content): Observable<T> {
    return this.apiService.Call<T>('common', 'svcLegalEntity', 'UpdateLegalEntityContactInfo', content);
  }

  public loginSuccess(result: ILogin): void {
    if (!FW.isNull(result)) {
      localStorage.setItem("storeuser", '0');
      localStorage.removeItem("username");
      localStorage.setItem("storepassword", '0');
      localStorage.removeItem("storedPassword");
      localStorage.setItem("lastsessiontoken", result.sessiontoken);
      sessionStorage.setItem("sessiontoken", result.sessiontoken);
      sessionStorage.setItem("customizationkey", result.customizationkey.toString());
      sessionStorage.setItem("currentlanguage", result.language);
      sessionStorage.setItem("username", result.username);
      sessionStorage.setItem("useremail", result.useremail);

      if (!FW.isNullOrBlank(result.photourl)) {
        sessionStorage.setItem("userphotourl", result.photourl);
      } else {
        sessionStorage.removeItem("userphotourl");
      }
    }
  }

  doChangePassword<T>(storagedPassword: string, newPassword: string,
    passwordConfirm: string): Observable<T> {
    var ChangeContent = {
      'currentPassword': '',
      'newPassword': '',
      'passwordConfirm': ''
    };
    if (!FW.isNullOrEmpty(storagedPassword)) {
      ChangeContent.currentPassword = MD5(storagedPassword).toString()
    }
    if (!FW.isNullOrEmpty(newPassword)) {
      ChangeContent.newPassword = MD5(newPassword).toString();
    }
    if (!FW.isNullOrEmpty(passwordConfirm)) {
      ChangeContent.passwordConfirm = passwordConfirm;
    }
    return this.apiService.Call<T>('framework', 'svcuser', 'changecurrentuserpassword', ChangeContent);
  }

  changeforgottenpassword<T>(token: string, newPassword: string,
    confirmPassword: string): Observable<T> {
    var ChangeContent = {
      'token': '',
      'newPassword': '',
      'confirmPassword': ''
    };
    ChangeContent.token = token;
    if (!FW.isNullOrEmpty(newPassword)) {
      ChangeContent.newPassword = newPassword;
    }
    if (!FW.isNullOrEmpty(confirmPassword)) {
      ChangeContent.confirmPassword = confirmPassword;
    }
    return this.apiService.Call<T>('framework', 'svcuser', 'changeforgottenpassword', ChangeContent);
  }

  VerifyEmailHasLegalEntity<T>(email: string): Observable<T> {
    var Content = {
      'email': ''
    };
    Content.email = email;

    return this.apiService.Call<T>('common', 'svcLegalEntity', 'VerifyEmailHasLegalEntity', Content);
  }

  public ChangePasswordSuccess(result: IChangePassword): void {
    if (!FW.isNull(result)) {
    }
  }

  public ToInterfaceConfiguration<T>(): Observable<T> {
    const interfaceConfigurationContent = {
      'appGuid': 'A8469023-956A-4F46-BCA3-1CE968AC150C',
    };

    return this.apiService.Call<T>('framework', 'svcEnvironment', 'GetAppSettings', interfaceConfigurationContent);
  }

  public InterfaceConfigurationSuccess(result: IInterfaceConfiguration): void {
    if (!FW.isNull(result)) {
      document.title = result.Name;
      localStorage.setItem("Name", result.Name);
      localStorage.setItem("SmallLogoUrl", result.SmallLogoUrl);
      localStorage.setItem("LogoUrl", result.LogoUrl);
      localStorage.setItem("AllowsSignUp", JSON.stringify(result.AllowsSignUp));
      localStorage.setItem("BaseUrl", result.BaseUrl);
      localStorage.setItem("PasswordRecoveryPath", result.PasswordRecoveryPath);
    }
  }

  public ToRequestPasswordChange<T>(email: string): Observable<T> {
    const requestPasswordContent = {
      'email': email,
      'applicationGUID': "A8469023-956A-4F46-BCA3-1CE968AC150C"
    };

    return this.apiService.Call<T>('framework', 'svcuser', 'requestpasswordchange', requestPasswordContent);
  }

}
