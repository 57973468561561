export class Queryable<T> {
    private _wrapped: Array<T> = null;

    constructor(collection: Array<T>) {
        this._wrapped = collection;
    }

    public select(selector: (item: T) => any): Array<any> {
        let result: Array<any> = new Array<any>();

        if (this._wrapped && this._wrapped.length && selector) {
            for (let i = 0; i < this._wrapped.length; i++) {
                result.push(selector(this._wrapped[i]));
            }
        }

        return result;
    }

    public where(comparer: (item: T) => boolean): Array<T> {
        let result: Array<T> = new Array<T>();

        if (this._wrapped && this._wrapped.length && comparer) {
            for (let i = 0; i < this._wrapped.length; i++) {
                if (comparer(this._wrapped[i])) {
                    result.push(this._wrapped[i]);
                }
            }
        }

        return result;
    }

    public first(comparer: (item: T) => boolean): T {
        let result: Array<T> = this.where(comparer);
        if (result.length > 0) {
            return result[0];
        } else {
            return null;
        }
    }

    public any(comparer: (item: T) => boolean): boolean {
        if (this._wrapped && this._wrapped.length && comparer) {
            for (let i = 0; i < this._wrapped.length; i++) {
                if (comparer(this._wrapped[i])) { return true; }
            }
        }

        return false;
    }
}