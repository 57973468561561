import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService) { }

  public confirmRegistration<T>(token: string): Observable<T> {
    return this.apiService.Call<T>("framework", "svcUser", "ConfirmRegistration", {
      "token": token
    });
  }
}
