export class Storage {
    public getString(key: string, defaultValue?: string): string {
        const result: string = localStorage.getItem(key);
        return (result || defaultValue);
    }

    public setString(key: string, value: string): void {
        localStorage.setItem(key, value);
    }
}
