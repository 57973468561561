import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UserService } from 'src/app/services/user.service';
import { FW } from '../../framework/core';
import { ILogin } from 'src/app/interfaces/ILogin';
import { MessageService } from 'src/app/services/message.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.css']
})
export class ConfirmRegistrationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private securityService: SecurityService,
    private messageService: MessageService) { }

  public token: string;

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get("token");
    if (!FW.isNullOrBlank(this.token)) {
      setTimeout(() => {
        this.userService.confirmRegistration<ILogin>(this.token)
          .subscribe(
            (result) => {
              this.securityService.loginSuccess(result);
            },
            error => this.onError(error)
          );
      }, 5000);
    }
  }

  public onError(error: any) {
    this.messageService.displayMessage(error);
  }

}
