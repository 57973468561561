import { Injectable } from '@angular/core';
import { Router, CanActivate } from "@angular/router";
import { FW } from "../framework/core";
import { AuthService } from "./auth.service";
declare var getQueryString: any;
@Injectable()
export class RouteGuardService implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate() {

        if (!this.authService.isSessionActive) {
            console.log("Authentication required!");
            if (!FW.isNullOrBlank(getQueryString("goto"))) {
                window.top.location.href = "./#/login?goto=" + encodeURIComponent(getQueryString("goto"));

            } else {
                window.top.location.href = "./#/login?goto=" + encodeURIComponent(window.location.href);
            }
            return false;
        }

        return true;
    }
}
