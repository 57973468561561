import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SecurityService } from 'src/app/services/security.service';
import { FW } from '../../framework/core';
import { ILogin } from 'src/app/interfaces/ILogin';
import { MessageService } from 'src/app/services/message.service';
import { IInterfaceConfiguration } from 'src/app/interfaces/IInterfaceConfiguration';
import { ILegalEntity } from 'src/app/interfaces/ILegalEntity';
import { InfoRequestService } from 'src/app/services/inforequest.service';

declare var viewPassword: any;
@Component({
    selector: 'app-inforequest',
    templateUrl: './inforequest.component.html'
})
export class InfoRequestComponent implements OnInit {
    public email;
    public password = '';
    public allowsSignUp = false;
    public name;
    public logo;
    public infoemail = [];
    public infoname = "";
    public guid = "";
    public secretcode;
    public confirmedEmail = false;
    public show = false;
    constructor(
        private securityService: SecurityService,
        private messageService: MessageService,
        private infoRequestService: InfoRequestService,
        private route: ActivatedRoute,
        private router: Router,
        private cdRef: ChangeDetectorRef) { }

    public ngOnInit() {
        this.loader(true);
        this.guid = this.route.snapshot.paramMap.get('guid');
        this.securityService.ToInterfaceConfiguration<IInterfaceConfiguration>()
            .subscribe(
                (result: IInterfaceConfiguration) => {
                    this.securityService.InterfaceConfigurationSuccess(result);
                    this.loadingSession();
                },
                error => this.onError(error)
            );
        this.GetInfoRequest();
    }

    public GetInfoRequest() {
        this.infoRequestService.GetInfoRequest(this.guid).subscribe((result: any) => {
            if (result != null) {
                this.infoemail = result.ContactEmail.split(";");
                this.infoname = result.ContactName;
            }
            this.loader(false);
        });
    }

    public loadingSession() {
        this.allowsSignUp = JSON.parse(localStorage.getItem("AllowsSignUp"));
        this.name = localStorage.getItem("Name");
        this.logo = localStorage.getItem("LogoUrl");
    }

    public loader(action: boolean) {
        if (action !== this.show) {
            this.show = action;
            this.cdRef.detectChanges();
        }
    }

    public SendTempPassword() {
        if (this.show) return;
        this.loader(true);
        this.infoRequestService.SendTempPassword(this.guid, this.email)
            .subscribe(
                (result: ILogin) => {
                    this.confirmedEmail = true;
                    this.loader(false);
                    // this.router.navigate(['/inforequestlogin/', this.guid]);
                },
                error => this.onError(error)
            );
    }

    public GetInfoRequestTemplate() {
        if (this.show) return;
        this.loader(true);
        this.infoRequestService.GetInfoRequestTemplate(this.guid, this.secretcode)
            .subscribe(
                (result: any) => {
                    this.loader(false);
                    sessionStorage.removeItem('sessiontoken');
                    sessionStorage.setItem("temptoken", result.ExtendedProperties.SessionToken);
                    this.router.navigate(['/template'], { queryParams: { request: this.guid, template: result.GUID } });
                },
                error => this.onError(error)
            );
    }

    public onError(error: any) {
        this.loader(false);
        this.messageService.displayMessage(error);
    }
}
