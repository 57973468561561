import { Component, ElementRef, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

import { isArray } from 'util';
import { IKeyValuePair, IKeyValuePairGroup } from '../interfaces/interfaces';
import { FW } from '../framework/core';
import { IfStmt } from '@angular/compiler';


declare var $: any;

@Component({
    selector: 'combobox-option-group',
    templateUrl: './combobox-option-group.component.html'
})

export class ComboBoxOptionGroupComponent implements AfterViewInit {
    @ViewChild('dropdown') private dropdown: ElementRef;
    @Input() id: string;
    @Input() multipleSelection: boolean;
    @Input() placeholderText: string;
    @Input() image: boolean;
    @Input() selectedValue: any;
    @Output() selectedValueChange = new EventEmitter<any>();

    @Output() change = new EventEmitter<any>();

    @Input() dataSource: any[];
    @Input() valuePropertyName: string;
    @Input() textPropertyName: string;

    public initialOptions: Array<IKeyValuePairGroup> = null;

    private _hasChangeEventHandler: boolean = false;

    constructor() {
        this.placeholderText = "Nenhum item selecionado";
        this.valuePropertyName = "id";
        this.textPropertyName = "displayText";
    }

    private verifySelection(item: IKeyValuePair): boolean {
        let result: boolean = false;
        if (isArray(this.selectedValue)) {
            result = FW.query(this.selectedValue).any(i => i == item.key);
        } else {
            result = this.selectedValue == item.key;
        }
        return result;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        let selectElement = $(this.dropdown.nativeElement);

        if (changes["dataSource"]) {
            selectElement.empty();

            selectElement.append(new Option(this.placeholderText, ""));

            if (FW.isNullOrEmpty(this.dataSource)) {
                this.initialOptions = null;
                return;
            } else {
                this.initialOptions = FW.query(this.dataSource).select((i) => { 
                    return { key: i[this.valuePropertyName], value: i[this.textPropertyName], parent: i.Parent }
                });
            }

            for (let i = 0; i < this.initialOptions.length; i++) {
                const selected = this.verifySelection(this.initialOptions[i]); 
                let group = 0;
                if (FW.isNullOrEmpty(this.initialOptions[i].parent)) {
                    if (group == 0) {
                        selectElement.append('<optgroup label=' + this.initialOptions[i].value + '>');
                    } else {
                        selectElement.append('</optgroup><optgroup label=' + this.initialOptions[i].value + '>');
                    }
                } else {
                    selectElement.append(new Option(this.initialOptions[i].value, this.initialOptions[i].key, selected, selected));
                } 
            }
            selectElement.append('</optgroup>');
        }

        if (changes["selectedValue"]) {
            if (FW.isNull(this.selectedValue)) {
                selectElement.val("");
            } else {
                selectElement.val(this.selectedValue.toString());
            }
        }

        this.initSelect2();
    }

    public ngAfterViewInit() {
        this.initSelect2();
    }

    public format(state) {
        if (this.image) {
            return "<i class='fa" + state.text + "'></i>" + state.text;
        }
        else {
            state.text;
        }
    }

    public initSelect2() {
        if (!$.fn.select2) { return; }

        let selectElement = $(this.dropdown.nativeElement);

        selectElement.select2({
            allowClear: !this.multipleSelection,
            dropdownAutoWidth: true,
            placeholder: this.placeholderText,
            width: '100%',
            formatResult: this.format,
            formatSelection: this.format
        });

        if (!this._hasChangeEventHandler) {
            selectElement.on('change', (e: any) => {
                this.onSelectionChanged($(e.target).val());
            });
            this._hasChangeEventHandler = true;
        }
    };

    public onSelectionChanged(newSelectedValue: any): void {
        this.selectedValueChange.emit(newSelectedValue);
        this.change.emit(newSelectedValue);
    }
}