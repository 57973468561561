import { Component, OnInit } from '@angular/core';
import { ILegalEntityTypeEntity } from 'src/app/interfaces/ILegalEntityTypeEntity';
import { LegalEntityTypeService } from 'src/app/services/legalentitytype.service';
import { MessageService } from 'src/app/services/message.service';
import { IdentificationTypeService } from 'src/app/services/identification-type.service';
import { IIdentificationTypeEntity } from 'src/app/interfaces/IIdentificationTypeEntity';
import { FW } from 'src/app/framework/core';
import { ILegalEntity } from 'src/app/interfaces/ILegalEntity';
import { LegalEntityService } from 'src/app/services/legalEntity.service';

declare var $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  public listLegalEntityTypeEntity: Array<ILegalEntityTypeEntity>;
  public selectedEntityType: number;
  public identType: IIdentificationTypeEntity;
  public mask: string;
  public documentNumber: string;
  public name: string;
  public surName: string;
  public email: string;
  public password: string;

  constructor(private legalEntityTypeService: LegalEntityTypeService,
    private messageService: MessageService,
    private identificationTypeService: IdentificationTypeService,
    private legalEntityService: LegalEntityService) { }

  ngOnInit() {
    this.SearchLegalEntityTypeEntity();
    this.clearForm();
  }

  public SearchLegalEntityTypeEntity() {
    this.legalEntityTypeService.Search<Array<ILegalEntityTypeEntity>>(null)
      .subscribe(
        (result: Array<ILegalEntityTypeEntity>) => {
          this.listLegalEntityTypeEntity = result;
        },
        error => this.onError(error)
      );
  }

  public onChange(entityType) {
    this.clearIdentification();
    this.identificationTypeService.GetByLegalEntityType(entityType)
      .subscribe(
        (result) => {
          this.identType = result;
          this.mask = FW.replaceAll(this.identType.Mask, '9', '0');
        },
        (error) => this.onError(error)
      );
  }

  private clearIdentification(): void {
    this.identType = null;
    this.mask = null;
    this.documentNumber = null;
  }

  private clearForm(): void {
    this.selectedEntityType = undefined;
    this.name = null;
    this.surName = null;
    this.email = null;
    this.password = null;
    this.clearIdentification();
  }

  public register(): void {
    let legalEntity: ILegalEntity = {
      Name: `${this.name} ${this.surName}`,
      IdentificationNumber: this.documentNumber,
      Type: this.selectedEntityType,
      IdentificationType: this.identType.Id,
      Email: this.email,
      ExtendedProperties: {
        "Email": this.email,
        "EmailConfirm": this.email,
        "Password": this.password,
        "PasswordConfirm": this.password
      }
    };

    this.legalEntityService.registerNewCustomerAccount(legalEntity)
      .subscribe(
        (result) => {
          this.clearForm();
          $('#registerSuccess').modal();
        },
        (error) => this.onError(error)
      );
  }

  public onError(error: any) {
    this.messageService.displayMessage(error);
  }

}
