
import { NgModule } from "@angular/core";
import { ComboBoxOptionGroupComponent } from "src/app/controls/combobox-option-group.component";
import { ComboBoxComponent } from "src/app/controls/combobox.component";
@NgModule({
    imports: [
    ],
    declarations: [
        ComboBoxComponent,
        ComboBoxOptionGroupComponent
    ],
    exports: [
        ComboBoxComponent,
        ComboBoxOptionGroupComponent
    ]
})
export class SharedModule { }