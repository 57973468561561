import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { LoaderService } from "./services/loader.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-router",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked  {
    public show = false;
    private subscription: Subscription;

    constructor(private loaderService: LoaderService, 
        private cdRef : ChangeDetectorRef) {
    }

    public ngOnInit() {
        this.subscription = this.loaderService.loaderState
            .subscribe((state: number) => {
                this.show = state > 0;
            });
    }
    
    ngAfterViewChecked(): void {
        let show = this.loaderService.apiCallCounter > 0;
        if(show !== this.show) {
            this.show = show;
            this.cdRef.detectChanges();
        }
    }
    
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onRouteChange() { }
}
