import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaLoaderService {
  private isLoaded = false;
  private loadPromise: Promise<void>;

  recaptchaSiteKey = "6LexqFUfAAAAAFn7GGq9zUaSOUNI2Id4GfEBxO5J";

  load(): Promise<void> {
    if (this.isLoaded) {
      return Promise.resolve();
    }

    if (this.loadPromise) {
      return this.loadPromise;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaSiteKey}`;
    script.async = true;
    script.defer = true;

    this.loadPromise = new Promise<void>((resolve, reject) => {
      script.onload = () => {
        this.isLoaded = true;
        resolve();
      };
      script.onerror = () => {
        reject('Failed to load reCAPTCHA script');
      };
    });

    document.head.appendChild(script);

    return this.loadPromise;
  }
}
